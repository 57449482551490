import { useState, useEffect, type FC } from 'react'

import { Chevron } from '@/assets/icons/Chevron'
import { cn } from '@/lib/utils'

interface SubNavButtonProps {
  item: {
    label: string
  }
  toggleDropdown: () => void
  showDropdown: boolean
  isSecondLevel?: boolean
  childNavOpen?: boolean
}

const SubNavButton: FC<SubNavButtonProps> = ({
  item,
  toggleDropdown,
  showDropdown,
  isSecondLevel = false,
  childNavOpen = false,
}) => (
  <button
    onClick={toggleDropdown}
    className={cn(
      'flex uppercase w-full text-blue text-sm justify-between items-center font-semibold border-b-lightBlue',
      isSecondLevel && 'text-lightestBlue text-xs pb-[11px]',
      !showDropdown && isSecondLevel && 'border-b-[1px]'
    )}
    aria-label="View all"
    aria-expanded={showDropdown}
  >
    {item.label}
    <div className={cn('transition', showDropdown ? 'rotate-180' : '')}>
      {!childNavOpen && <Chevron />}
    </div>
  </button>
)

const Link = ({ uri, label }: { uri: string; label: string }) => {
  return (
    <li className="text-blue-light text-sm font-semibold">
      <a href={uri}>{label}</a>
    </li>
  )
}

const SubNavItem = ({ subItem, setChildNavOpen }) => {
  const [showSubDropdown, setShowSubDropdown] = useState(false)
  const toggleSubDropdown = () => setShowSubDropdown(!showSubDropdown)
  useEffect(() => {
    setChildNavOpen(showSubDropdown)
  }, [showSubDropdown, setChildNavOpen])
  if (subItem.childItems?.nodes.length > 0) {
    return (
      <>
        <SubNavButton
          item={subItem}
          toggleDropdown={toggleSubDropdown}
          showDropdown={showSubDropdown}
          isSecondLevel
        />
        {showSubDropdown && (
          <ul className="flex flex-col gap-[11px] border-b-[1px] border-b-lightBlue pb-[11px] -mt-[11px] list-none ml-0">
            {subItem.childItems?.nodes.map((subSubItem) => (
              <li key={`subsub-${subSubItem.id}`} className="text-blue text-sm font-semibold">
                <Link uri={subSubItem.uri} label={subSubItem.label} />
              </li>
            ))}
          </ul>
        )}
      </>
    )
  } else {
    return <Link uri={subItem.uri} label={subItem.label} />
  }
}

const MobileNavItem = ({ item, menuItems }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [childNavOpen, setChildNavOpen] = useState(false)

  const toggleDropdown = () => setShowDropdown(!showDropdown)

  return (
    <>
      <li
        className={cn(
          'uppercase text-blue text-sm py-[11px] font-bold flex justify-between transition border-b-lightBlue',
          !showDropdown && !childNavOpen && 'border-b-[1px]'
        )}
      >
        {item.childItems?.nodes.length > 0 ? (
          <SubNavButton
            item={item}
            toggleDropdown={toggleDropdown}
            showDropdown={showDropdown}
            childNavOpen={childNavOpen}
          />
        ) : (
          <a href={item.uri}>{item.label}</a>
        )}
      </li>
      {showDropdown && (
        <ul className={cn('flex flex-col gap-[11px] [&_a]:text-blue-light list-none m-0 p-0')}>
          {menuItems
            .filter((subItem) => subItem.parentId === item.id)
            .map((subItem) => (
              <SubNavItem
                key={`sub-${subItem.id}`}
                subItem={subItem}
                setChildNavOpen={setChildNavOpen}
              />
            ))}
        </ul>
      )}
    </>
  )
}

export const MobileNav = ({ menuItems }) => (
  <div className="absolute bg-white h-screen w-screen z-[60] [&_a]:no-underline [&_a]:text-blue top-13 left-0">
    <div className="flex flex-col justify-between container py-[27px] bg-white">
      <ul className={cn('flex flex-col  gap-[11px] m-0 p-0 list-none')}>
        {menuItems
          .filter((item) => item.parentId === null)
          .map((item) => (
            <MobileNavItem key={item.id} item={item} menuItems={menuItems} />
          ))}
      </ul>
      <div className="text-blue mt-4">
        Email:{' '}
        <a href="mailto:bookings@nomadsworld.com" className="!text-pink !underline">
          bookings@nomadsworld.com
        </a>
      </div>
    </div>
  </div>
)
