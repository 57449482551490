import { useState, type FC } from 'react'
import { DialogContent } from '@radix-ui/react-dialog'

import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
} from '../desktop-nav/DesktopNav'
import { Hamburger } from '../hamburger/Hamburger'
import { MobileNav } from '../mobile-nav/MobileNav'

import { NomadsLogo } from '@/assets/NomadsLogo'
import { Button, buttonVariants } from '@/components/react/atoms/button/Button'
import {
  Dialog,
  DialogClose,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from '@/components/react/atoms/dialog/dialog'
import { cn } from '@/lib/utils'
import { BookingBar } from '@/components/react/features/booking-bar/BookingBar'

interface NavBarProps {
  menu: any
  properties: any
}

export const NavBar: FC<NavBarProps> = ({ menu, properties }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [bookingModalOpen, setBookingModalOpen] = useState(false)

  const menuItems = menu?.menuItems?.nodes
  return (
    <nav className={cn('text-neutral-700 z-50 transition-all duration-150 ease-in-out container')}>
      <div className="flex justify-between items-center pt-[14px] md:pt-4 lg:pt-[18px] pb-[18px] lg:pb-[26px]">
        <a className="!text-blue" href="/">
          <NomadsLogo />
        </a>
        <div className="flex gap-5 items-center">
          <div className="hidden xl:block">
            <NavigationMenu>
              <NavigationMenuList>
                {menuItems
                  .filter((item) => item.parentId === null)
                  .map((item) => {
                    if (item.childItems.nodes.length < 1)
                      return (
                        <NavigationMenuItem key={item.id}>
                          <NavigationMenuLink href={item.uri}>{item.label}</NavigationMenuLink>
                        </NavigationMenuItem>
                      )

                    return (
                      <NavigationMenuItem key={item.id}>
                        <NavigationMenuTrigger>{item.label}</NavigationMenuTrigger>

                        <NavigationMenuContent className={item.cssClasses}>
                          {
                            // Logi for dealing with the location menu
                            item.cssClasses.includes('location') ? (
                              <div className="container  m-auto grid grid-cols-12 gap-8 pt-8 pb-16">
                                <div className="hidden 2xl:block 2xl:col-span-1"></div>
                                {menuItems
                                  .filter((subItem) => subItem.parentId === item.id)
                                  .map((subItem) => {
                                    return (
                                      <div
                                        key={subItem.id}
                                        className={cn(
                                          subItem.childItems.nodes.length > 4
                                            ? 'xl:col-span-3 2xl:col-span-4'
                                            : 'xl:col-span-3 2xl:col-span-2'
                                        )}
                                      >
                                        <h3 className=" text-blue text-[20px]  font-sans font-semibold pb-4  border-lightBlue border-b-[1px] tracking-[2.4px]">
                                          {subItem.label}
                                        </h3>
                                        <div
                                          className={cn(
                                            'mt-4 grid gap-3 text-lg',
                                            subItem.childItems.nodes.length > 4
                                              ? '2xl:grid-cols-2'
                                              : 'grid-cols-1'
                                          )}
                                        >
                                          {subItem.childItems.nodes.map((childItem) => {
                                            return (
                                              <NavigationMenuLink
                                                href={childItem.uri}
                                                key={childItem.id}
                                              >
                                                {childItem.label}
                                              </NavigationMenuLink>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )
                                  })}
                                <div className="col-span-3 2xl:col-span-2 justify-self-end px-4 flex 2xl:items-center">
                                  <a
                                    href="/destinations"
                                    data-astro-prefetch
                                    className={buttonVariants({
                                      size: 'sm',
                                      variant: 'default',
                                      shape: 'square',
                                      fullWidth: true,
                                    })}
                                  >
                                    {' '}
                                    View all destinations
                                  </a>
                                </div>
                                <div className="hidden 2xl:block 2xl:col-span-1"></div>
                              </div>
                            ) : (
                              menuItems
                                .filter((subItem) => subItem.parentId === item.id)
                                .map((subItem) => {
                                  return (
                                    <NavigationMenuLink href={subItem.uri} key={subItem.id}>
                                      {subItem.label}
                                    </NavigationMenuLink>
                                  )
                                })
                            )
                          }
                        </NavigationMenuContent>
                      </NavigationMenuItem>
                    )
                  })}
              </NavigationMenuList>
            </NavigationMenu>
          </div>
          <Dialog open={bookingModalOpen} onOpenChange={setBookingModalOpen}>
            <DialogTrigger asChild>
              <Button>Book now</Button>
            </DialogTrigger>
            <DialogPortal>
              <DialogOverlay className="fixed inset-0 bg-black bg-opacity-80" />

              <DialogContent
                id="booking-modal"
                className="fixed inset-0 flex flex-col items-center justify-center p-4 z-50"
              >
                <div>
                  <DialogClose className="text-right ml-[100%] font-2xl text-white font-black font-gotham">
                    x
                  </DialogClose>
                  <h2 className="text-center text-white">Make a booking</h2>
                </div>
                <BookingBar
                  properties={properties}
                  className="mt-4"
                  vertical
                  setBookingModalOpen={setBookingModalOpen}
                />
              </DialogContent>
            </DialogPortal>
          </Dialog>

          <Hamburger isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>
      </div>

      {isMenuOpen && <MobileNav menuItems={menuItems} />}
    </nav>
  )
}
