import React, { type FC } from 'react'

import { cn } from '@/lib/utils'

interface HamburgerProps {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const Hamburger: FC<HamburgerProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const genericHamburgerLine = `h-[2px] bg-neutral-500  transition ease transform duration-300`

  return (
    <button
      className="flex items-center flex-col-reverse xl:hidden "
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      aria-label={isMenuOpen ? 'Close' : 'Open menu'}
      aria-expanded={isMenuOpen}
    >
      <div
        className={cn(
          'flex flex-col h-7 w-7 justify-center items-center group border-[1px] border-solid border-neutral-200  rounded focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50 transition',
          isMenuOpen ? 'gap-0' : 'gap-0.5'
        )}
      >
        <div
          className={cn(
            genericHamburgerLine,
            isMenuOpen
              ? 'rotate-45 translate-y-2 group-hover:opacity-100 my-1 w-4'
              : ' group-hover:opacity-100  w-4'
          )}
        />
        <div
          className={`${genericHamburgerLine} ${
            isMenuOpen ? 'opacity-0 my-1 w-4' : ' group-hover:opacity-100  w-4'
          }`}
        />
        <div
          className={`${genericHamburgerLine} ${
            isMenuOpen
              ? '-rotate-45 -translate-y-2.5 group-hover:opacity-100 w-4 my-1'
              : ' group-hover:opacity-100 w-4 '
          }`}
        />
      </div>
    </button>
  )
}
